<template>
  <div class="home z-0">

    <div class="w-full my-4 mb-16 md:mb-0 md:flex md:justify-between md:space-x-12 md:items-center">
      <div class="w-full px-4 md:px-0 mb-8 md:mb-0 md:max-w-[600px]">
        <h1 class="mb-4">
          <div class="text-3xl md:text-6xl font-bold block mb-2 font-custom uppercase">
            Onde tem
            <FlipWords
              :words="flipWords"
              :duration="2500"
              class="text-3xl md:text-6xl font-bold block mb-2 font-custom uppercase"
            />
            <p class="font-black text-brand block">tem Pixta</p>
          </div>
          <span class="text-xl md:text-3xl font-bold uppercase block mb-3 font-custom">
            Para quem produz &amp; para quem se joga!
          </span>
          <span class="text-md block mb-4">
            A Pixta é uma plataforma de venda e compra de ingressos que está aqui para ajudar você, produtor cultural e
            organizador de eventos, a gerenciar, vender, engajar e movimentar sua pixta.
            <br>Com facilidade, segurança e agilidade.
          </span>
          <!-- <span class="text-5xl font-bold block leading-none mb-2">eventos inesquecíveis começam aqui</span>
          <span class="leading-loose text-lg">Crie a página do seu evento, convide amigos e venda ingressos com facilidade. Organize experiências memoráveis com a nossa plataforma.</span> -->
        </h1>

        <div class="flex justify-start">
          <NuxtLink to="/como-funciona" class="flex font-bold text-lg py-3 px-6 rounded border-black border-2 color-black hover:scale-110 hover:bg-black hover:text-white dark:border-white hover:dark:bg-white hover:dark:text-black w-fit transition-all btn-primary">
            crie seu evento
          </NuxtLink>
        </div>
      </div>

      <div class="video-bg hidden md:block bg-zinc-100 dark:bg-zinc-900 rounded">
        <video
          muted
          autoplay
          playsinline
          disableremoteplayback
          disablepictureinpicture
          preload="metadata" loop
          poster="/splash-videos/splash-site-smaller.png"
          class="object-cover h-full rounded [filter:url(#ambilight)]">
          <source class="" src="/splash-videos/splash-site-smaller.mp4" type="video/mp4" />
        </video>
      </div>
    </div>

    <div class="mx-2 mt-4 mb-8 page-title-text relative flex space-between items-start">

      <h1 class="font-medium cursor-default text-2xl">
        Eventos em

        <CityCurrent class="inline-flex items-center cursor-pointer" @click.stop.prevent="openCitySelector">
          <template v-slot="{ currentCity }">
            <span v-if="currentCity?.id" class="font-normal tracking-normal">{{ currentCity.name }}/{{ currentCity.uf}}</span>
            <span v-else class="font-normal tracking-normal">Todo o Brasil</span>
            <icon-chevron-down />
          </template>
        </CityCurrent>

      </h1>
    </div>

    <div class="grid sm:gap-2 sm:grid-cols-2 md:gap-4 md:grid-cols-2 lg:gap-8 lg:grid-cols-3 xl:gap-4 xl:grid-cols-4">

      <NuxtLink no-prefetch class="w-full" :to="`/u/${event.slug}`" v-for="event,idx in events" :key="idx">
        <EventCardFull :event="event" />
      </NuxtLink>
    </div>

    <div class="main-cta flex justify-center">
      <NuxtLink no-prefetch v-if="currentCity?.id" :to="`/eventos/${currentCity?.slug}`" class="flex font-bold text-lg py-3 px-6 rounded border-black border-2 color-black dark:border-white w-fit transition-all btn-primary">
        ver mais eventos em {{ currentCity?.name }}
      </NuxtLink>
      <NuxtLink no-prefetch v-else :to="`/busca`" class="flex font-bold text-lg py-3 px-6 rounded border-black border-2 color-black dark:border-white w-fit transition-all btn-primary">
        ver mais eventos em Todo o Brasil
      </NuxtLink>
    </div>

    <div class="flex md:space-x-2 md:space-between my-8 md:my-32 w-full md:p-4 py-12 md:py-32 flex-wrap relative">
      <div class="flex-1 p-4 md:p-12">
        <h1 class="mb-4 font-bold text-3xl md:text-5xl">
          <span class="block font-custom">Movimentando arte.</span>
          <span class="block font-custom">Alimentando cultura.</span>
          <span class="block font-custom">Conectando pessoas.</span>
        </h1>

        <p class="mb-4">
          Mais do que uma plataforma de ingressos, a Pixta também é uma agitadora cultural e
          nosso propósito é dar espaço para quem faz a cultura acontecer.
        </p>

        <p class="mb-4">
          Acesse nossa página e confira nossos quadros de conteúdos e entrevistas,
          acompanhe e descubra quem são os artistas e profissionais por
          trás das pixtas da plataforma e do país.
        </p>

        <a href="https://instagram.com/pixta.me" class="mb-8 flex font-bold text-lg py-3 px-6 rounded border-black border-2 color-black dark:border-white w-fit transition-all btn-primary">
          veja @pixta.me no instagram
        </a>
      </div>

      <div class="block overflow-hidden w-full md:px-0 md:max-w-96 lg:max-w-screen-sm">
        <Swiper
          :modules="[SwiperAutoplay, SwiperEffectCoverflow, SwiperEffectFade, SwiperEffectCards]"
          :slides-per-view="1"
          :loop="true"
          :effect="'cards'"
          :autoplay="{
            delay: 1500,
            disableOnInteraction: false,
          }"
          :cards-effect="{
            crossFade: false,
            slideShadows: false
          }"
        >
          <SwiperSlide v-for="image in images" :key="image" class="">
            <!-- <strong>{{ image }}</strong> -->
            <NuxtImg :src="`/img/social_examples/${image}`" class="w-48 md:w-64 h-auto mx-auto" width="300" lazy />
          </SwiperSlide>
        </Swiper>
      </div>

      <div class="video-bg absolute flex items-center opacity-30 top-24 md:opacity-70 w-full md:w-auto md:h-full -z-10 md:-top-6 md:left-64 bg-zinc-100 dark:bg-zinc-900">
        <video muted
          autoplay
          playsinline
          disableremoteplayback
          disablepictureinpicture
          preload="metadata" loop class="hidden object-cover dark:block md:h-full md:scale-115 mix-blend-luminosity">
          <source class="" src="/home-videos/output-dark-smaller-24-gray.mp4" type="video/mp4" />
        </video>
        <video muted
          autoplay
          playsinline
          disableremoteplayback
          disablepictureinpicture
          preload="metadata" loop class="object-cover block dark:hidden md:h-full md:scale-115 mix-blend-luminosity">
          <source class="" src="/home-videos/output-light-smaller-24-gray.mp4" type="video/mp4" />
        </video>
      </div>

    </div>

    <svg width="0" height="0">
      <filter
          id="ambilight"
          width="300%"
          height="300%"
          x="-0.75"
          y="-0.75"
          color-interpolation-filters="sRGB"
      >
          <feOffset in="SourceGraphic" result="source-copy" />
          <feColorMatrix
              in="source-copy"
              type="saturate"
              values="1"
              result="saturated-copy"
          />
          <feColorMatrix
              in="saturated-copy"
              type="matrix"
              values="1 0 0 0 0
                      0 1 0 0 0
                      0 0 1 0 0
                      33 33 33 101 -122"
              result="bright-colors"
          />
          <feMorphology
              in="bright-colors"
              operator="dilate"
              radius="8"
              result="spread"
          />
          <feGaussianBlur
              in="spread"
              stdDeviation="30"
              result="ambilight-light"
          />
          <feOffset in="SourceGraphic" result="source" />
          <feComposite in="source" in2="ambilight-light" operator="over" />
      </filter>
    </svg>

    <PixtaApiError v-if="isApiOnError" />
  </div>
</template>

<script setup>

import { storeToRefs } from 'pinia'
import { useUserStore } from '@/store/user.js'

const userStore = useUserStore()
const { loggedIn: isLoggedIn, currentCity } = storeToRefs(userStore)

const isApiOnError = ref(false);

const route = useRoute()
const router = useRouter()

const runtimeConfig = useRuntimeConfig()
const { baseApiUrl: baseUrl } = runtimeConfig.public

const images = ref([
  'procure_dharma.jpg',
  'procure_gabs.jpg',
  'procure_luisa.jpg',
  'qpee_1010.jpg',
  'dow.jpg',
  'funk.jpg'
])

function shuffleArray(array) {
  return array.sort(() => Math.random() - 0.5);
}

const flipWordsOptions = [
  'teatro,',
  'cursos,',
  'palestras,',
  'balada,',
  'eventos,',
  'gastronomia,',
  'bares,',
  'workshops,'
]
const flipWords = computed(() => {
  return ['cultura,', ...shuffleArray(flipWordsOptions)]
})

const searchQuery = ref('')

const { data: events, refresh } = useFetch(() => `${baseUrl}/api/events/homepage.json?city=${currentCity.value.id || ''}&per_page=12`, { server: false })

import { useEventBus } from '@vueuse/core'
const bus = useEventBus('citySelectorModal')
const openCitySelector = () => {
  bus.emit(true)
}

useHead(() => {
  const pageTitle = `Pixta - Ingressos para seu eventos favoritos - Festas, shows, festivais, cursos e palestras`,
        pageDescription = `Evento cheio, sem stress. Ingressos para seus eventos favoritos na pixta.me`,
        pageImage = `https://pixta.me/og.jpeg`
  return {
    title: pageTitle,
    description: pageDescription,
    meta: [
      { property: 'og:image', content: pageImage },
      { property: 'og:image:secure_url', content: pageImage },
      { property: 'og:image:type', content: 'image/jpeg' },
      { property: 'og:image:width', content: '1200' },
      { property: 'og:image:height', content: '525' },
      { property: 'og:image:alt', content: pageTitle },
      { property: 'og:title', content: pageTitle },
      { property: 'og:site_name', content: 'PIXTA.me' },
      { property: 'og:url', content: `https://pixta.me` },
      { property: 'og:description', content: pageDescription },
      { property: 'og:type', content: 'website' },
      { property: 'fb:app_id', content: '1751665528367178' },
      { property: 'og:locale', content: 'pt_BR' },
      { itemprop: 'name', content: 'PIXTA.me' },
      { itemprop: 'description', content: pageDescription },
      { itemprop: 'image', content: pageImage },
      { name: 'description', content: pageDescription },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: 'pixta_me' },
      { name: 'twitter:title', content: pageTitle },
      { name: 'twitter:description', content: pageDescription },
      { name: 'twitter:image', content: pageImage },
    ]
  }
})

</script>
